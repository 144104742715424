import React from 'react';
import { useEffect, useState } from 'react';

import MemoryLearning from './memoryLearning';
import MemoryGame from './memoryGame';
import MemoryTestKnowledge from './memoryTestKnowledge';
import { useSearchParams } from 'react-router-dom'

import { createGameData, createInitialVariant } from './gameUtil';

export default function Memorize() {

    const [searchParams, setSearchParams] = useSearchParams();
    const [replay, setReplay] = useState(searchParams.get('replay') === 'true');
    const { secondsRemaining } = useRedirectAfterSomeSeconds();
    //const { secondsRemaining } = 5;
    const [ symbolColorMap, setSymMap ] = useState(() => createGameData(15));
    var [finished, setFinished] = useState(false);
    const [flippedOverallCount, setFlippedOverallCount] = useState(0);
    const [variant] = useState(createInitialVariant(searchParams.get('variant')), false);

    return (
        <div>
            <div>
                <div className='alert alert-info' style={{display: secondsRemaining <= 0 ? 'none' : 'default'}}>
                    Memory starts in 
                    <p style={{color: secondsRemaining < 5 ? 'red' : 'green'}}>
                        {secondsRemaining} {secondsRemaining > 1 ? 'seconds' : 'second'}.
                    </p>
                    Try to remember as many symbol to color associations as possible.
                </div>
                {!finished ?
                    <div>
                        {secondsRemaining <= 0 ? <MemoryGame symbolColorMap={symbolColorMap} variant={variant} setFinished={setFinished} flippedOverallCount={flippedOverallCount} setFlippedOverallCount={setFlippedOverallCount}/> : <MemoryLearning symbolColorMap={symbolColorMap}/>}
                    </div>
                    :
                    <div>
                        <MemoryTestKnowledge symbolColorMap={symbolColorMap} variant={variant} replay={replay} flippedOverallCount={flippedOverallCount}></MemoryTestKnowledge>
                    </div>
                }
            </div>
        </div>
    );
}


function useRedirectAfterSomeSeconds(seconds = 30) {
    const [secondsRemaining, setSecondsRemaining] = useState(seconds);
  
    useEffect(() => {
      //if (secondsRemaining === 0) router.push('/');
  
      const timer = setTimeout(() => {
        if (secondsRemaining >= 0) {
            setSecondsRemaining((prevSecondsRemaining) => prevSecondsRemaining - 1);
         } else {
            return;
         }
      }, 1000);
  
      return () => {
        clearInterval(timer);
      };
    }, [secondsRemaining]);
  
    return { secondsRemaining };
}
