import React from 'react';
import { useEffect, useState } from 'react';


import MemoryLearning from '../memory-game/memoryLearning';
import MemoryTestKnowledge from '../memory-game/memoryTestKnowledge';

import { createGameData } from '../memory-game/gameUtil';

import { useSearchParams, Link, useNavigate } from 'react-router-dom'


export default function Reference() {

    const [searchParams, setSearchParams] = useSearchParams();
    const [secondsRemaining, setSecondsRemaining] = useState(30);
    const [finishedTest, setFinished] = useState(false);
    const [ symbolColorMap, setSymMap ] = useState(() => createGameData(15));
    const navigate = useNavigate();

    if (searchParams.get('replay') === 'true') {
        navigate("../memorize?replay=true&variant="+searchParams.get('variant'))
    }
  
    useEffect(() => {
      const timer = setTimeout(() => {
        if (secondsRemaining > 0) {
            setSecondsRemaining((prevSecondsRemaining) => prevSecondsRemaining - 1);
         } else {
            return;
         }
      }, 1000);
  
      return () => {
        clearInterval(timer);
      };
    }, [secondsRemaining]);

    useEffect(() => {
        if (finishedTest === true) {
            setSecondsRemaining(200);
        }
    }, [finishedTest]);

    return (
        <div>
            <div>
                <h1>Stage 1 - creating a reference</h1>
                <div className='alert alert-info' style={{display: secondsRemaining <= 0 ? 'none' : 'default'}}>
                    The testing phase begins in 
                    <p style={{color: secondsRemaining < 5 ? 'red' : 'green'}}>
                        {secondsRemaining} {secondsRemaining > 1 ? 'seconds' : 'second'}.
                    </p>
                    Try to remember as many symbol to color associations as possible.
                </div>
                {!finishedTest ?
                    <div>
                        {secondsRemaining <= 0 ?   <MemoryTestKnowledge symbolColorMap={symbolColorMap} variant={searchParams.get('variant')} replay={searchParams.get('replay')} setFinishedParent={setFinished}></MemoryTestKnowledge>
                                                : <MemoryLearning symbolColorMap={symbolColorMap}/>}
                    </div>
                    :
                    <div>
                        Please take a break before going to the next phase. The button is deactivated for 
                        <p style={{color: secondsRemaining < 5 ? 'red' : 'green'}}>{secondsRemaining} {secondsRemaining > 1 ? 'seconds' : 'second'}.</p>
                        <br/>
                        <div className='alert alert-danger'>The color/symbol associations you have learned so far are irrelevant for the next stage, you can forget them</div>
                        <br/>
                        <div>
                        { secondsRemaining <= 0 ?
                            <Link to={"../memorize?variant="+searchParams.get('variant')} disabled={secondsRemaining > 0 ? true : false}>
                                <button>If you feel ready go ahead and press here</button>
                            </Link>
                            :
                            <button>Please wait</button>
                        }
            </div>
                    </div>
                }
            </div>
        </div>
    );
}