import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import learning from './pictures/Learning.png';
import memory from './pictures/Memory.png';
import memoryV1 from './pictures/MemoryVariant1.png';
import memoryV2 from './pictures/MemoryVariant2.png';
import testing from './pictures/Testing.png';
import { Link } from 'react-router-dom';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
export default function HowToModal({replay, variant}) {
    const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Start the experiment
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Experiment procedure
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div style={{marginBottom: '20px'}}>
            <div className='alert alert-secondary'>This experiment takes around 15 (+/- 7) minutes to complete</div>
            <p>The experiment runs in two stages and each stage is structered in multiple phases</p>
            <p>{replay === 'true' ? 'Since you completed Stage 1 on your last try, it is skipped this time' :''}</p>
            <ul>
                <div style={{color: replay === 'true'? 'grey' : 'black'}}>
                    <li><p style={{fontSize: '1.5rem', marginBottom: '.5rem', fontWeight: '500', lineHeight: '1.2'}}>Stage 1 - creating a reference</p></li>
                    <ul>
                        <li><p style={{fontSize: '1rem', marginBottom: '.5rem', fontWeight: '500', lineHeight: '1.2'}}>Learning Phase</p></li>
                        <li><p style={{fontSize: '1rem', marginBottom: '.5rem', fontWeight: '500', lineHeight: '1.2'}}>Testing Phase</p></li>
                    </ul>
                    <li>Break (3 minutes)</li>
                </div>
                <li><p style={{fontSize: '1.5rem', marginBottom: '.5rem', fontWeight: '500', lineHeight: '1.2'}}>Stage 2 - testing with additional training</p></li>
                <ul>
                    <li><p style={{fontSize: '1rem', marginBottom: '.5rem', fontWeight: '500', lineHeight: '1.2'}}>Learning Phase</p></li>
                    <li><p style={{fontSize: '1rem', marginBottom: '.5rem', fontWeight: '500', lineHeight: '1.2'}}>Training Phase</p></li>
                    <li><p style={{fontSize: '1rem', marginBottom: '.5rem', fontWeight: '500', lineHeight: '1.2'}}>Testing Phase</p></li>
                </ul>
            </ul>
            <div className='alert alert-danger'>Pictures on this page are just examples</div>
            {replay === 'true' ? 
            <div className='alert alert-danger'>
              Please take a 2-5 minute break before starting the next attempt, thank you :)
            </div>: ''}
          </div>
          <hr/>
            <p style={{fontSize: '1.5rem', marginBottom: '.5rem', fontWeight: '500', lineHeight: '1.2'}}>Stage 1 - creating a reference</p>
          <hr/>
          <div style={{marginBottom: '20px'}}>
            <p style={{fontSize: '1rem', marginBottom: '.5rem', fontWeight: '500', lineHeight: '1.2'}}>Learning Phase</p>
            <p>You get symbols assigned to specific colors, which you have to remember</p>
            <p>You have 30 seconds time, remember as much as possible, it is intended that you cannot remember everything</p>
            <img
            src={learning}
            style={{width: '100%', border: 'solid'}}
            alt='Learning phase'
            loading="lazy"
            />
            
          </div>
          <hr/>
          <div>
            <p style={{fontSize: '1rem', marginBottom: '.5rem', fontWeight: '500', lineHeight: '1.2'}}>Testing Phase</p>
            <p>The symbols and the according color from the learning phase are tested</p>
            <div className='alert alert-info'>It is completely irrelevant how good you perform, just give it your best shot. It is hard/almost impossible on purpose.</div>
            <img
            src={testing}
            style={{width: '100%', border: 'solid'}}
            alt='Testing phase'
            loading="lazy"
            />
          </div>
          <hr/>
            <p style={{fontSize: '1.5rem', marginBottom: '.5rem', fontWeight: '500', lineHeight: '1.2'}}>Stage 2 - testing with additional training</p>
          <hr/>
          <div style={{marginBottom: '20px'}}>
            <p style={{fontSize: '1rem', marginBottom: '.5rem', fontWeight: '500', lineHeight: '1.2'}}>Learning Phase</p>
            <div className='alert alert-primary'>New cards are created, previous color/symbol associations from Stage 1 are not relevant anymore</div>
            <p>You get symbols assigned to specific colors, which you have to remember</p>
            <p>You have 30 seconds time, remember as much as possible, it is intended that you cannot remember everything</p>
            <img
            src={learning}
            style={{width: '100%', border: 'solid'}}
            alt='Learning phase'
            loading="lazy"
            />
            
          </div>
          <hr/>
          <div style={{marginBottom: '20px'}}>
            <p style={{fontSize: '1rem', marginBottom: '.5rem', fontWeight: '500', lineHeight: '1.2'}}>Training Phase</p>
            <p>You get to play a memory game matching cards</p>
            {variant ? 
            <div>   
                <p>You have to match cards that are identical</p>
                <img
                src={memoryV1}
                style={{width: '100%', border: 'solid'}}
                alt='Memory Variation'
                loading="lazy"
                />
            </div> : 
            <div>
                <p>You have to match cards that are related according to the matchings learned in the learning phase</p>
                <img
                src={memoryV2}
                style={{width: '100%', border: 'solid'}}
                alt='Memory Variation'
                loading="lazy"
                />
            </div>}
          </div>
          <hr/>
          <div>
            <p style={{fontSize: '1rem', marginBottom: '.5rem', fontWeight: '500', lineHeight: '1.2'}}>Testing Phase</p>
            <p>The symbols and the according color from the learning phase are tested</p>
            <div className='alert alert-info'>It is completely irrelevant how good you perform, just give it your best shot. It is hard/almost impossible on purpose.</div>
            <img
            src={testing}
            style={{width: '100%', border: 'solid'}}
            alt='Testing phase'
            loading="lazy"
            />
          </div>
        </DialogContent>
        <DialogActions>
            <div>
            {
                replay === 'true'?
                <Link to={"memorize?replay=true&variant="+variant}>
                    <button>If you feel ready for the second try, go ahead and press here, make sure to pause before</button>
                </Link>
                 :
                 <Link to={"reference?variant="+variant}>
                    <button>If you feel ready go ahead and press here</button>
                </Link>
            }
            </div>
        </DialogActions>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}