import React from 'react';
import { useEffect, useState } from 'react';

import { createCardsArrayDependingOnVariant, exchangeId } from './gameUtil';

export default function MemoryGame({ symbolColorMap, variant, setFinished, flippedOverallCount, setFlippedOverallCount }){

    const [cards, setCards] = useState(createCardsArrayDependingOnVariant(symbolColorMap, variant));
    const [flippedIndexes, setFlippedIndexes] = useState([]);
    const [flippedCorrectCount, setFlippedCount] = useState(0);
    const [feedback, setFeedback] = useState('To start press any card');

    useEffect(() => {
        if (flippedCorrectCount % 2 === 0 && flippedIndexes.length === 2) {

            setTimeout( () => {
                var match = cards[flippedIndexes[0]]?.id === cards[flippedIndexes[1]]?.id;

                if (!match && !variant) {
                    //console.debug('No direct id match, check if colors match')
                    //special heuristic for variant 2
                    if (cards[flippedIndexes[0]]?.backgroundColor === cards[flippedIndexes[1]]?.hiddenColor) {
                        //exchange id's
                        exchangeId(setCards, cards, flippedIndexes[0], flippedIndexes[1]);
                        match = true;
                    }
                    if (cards[flippedIndexes[1]]?.backgroundColor === cards[flippedIndexes[0]]?.hiddenColor) {
                        //exchange id's
                        exchangeId(setCards, cards, flippedIndexes[1], flippedIndexes[0]);
                        match = true;
                    }
                }

                //console.log('Two cards have been clicked' + flippedIndexes[0] + ' _ ' + flippedIndexes[1] + ' MATCH: '+match);
                if (match) {
                    cards[flippedIndexes[0]].flipped = true;
                    cards[flippedIndexes[1]].flipped = true;
                    setFlippedIndexes([]);
                    setFeedback('Very good');
    
                } else {
                    cards[flippedIndexes[0]].flipped = false;
                    cards[flippedIndexes[1]].flipped = false;
                    setFlippedIndexes([]);
                    setFlippedCount(flippedCorrectCount >= 2 ? flippedCorrectCount - 2 : flippedCorrectCount);
                    setFeedback('Try again');
                }

                setFlippedOverallCount(flippedOverallCount + 2);
            }, 1000);

            
            
        }
    }, [flippedIndexes, flippedCorrectCount, setFlippedIndexes, cards, variant, flippedOverallCount, setFlippedOverallCount])

    useEffect(() => {
        if (flippedCorrectCount === cards.length && flippedIndexes.length === 0) {
            console.log(flippedOverallCount);
            setFinished(true);
        }
    })

    useEffect(() => {
        // if feedback is not empty, clear after intervall
        if (feedback) {
            setTimeout(() => {
                setFeedback(null);
              }, 5000);
        }
    }, [feedback, setFeedback])

    //<h1>{variant ? 
    //'Variant 1':
    //'Variant 2'}</h1>
    return <>
        <div>
            <div>
                <h3>Match cards with {variant ? 'with similar content': 'symbol with the appropriatly colored card'}.</h3>
                <p>You have as many tries as you want.</p>
                <p>Immediately after you finish you will be forwarded to the next page.</p>
                <br/>
            </div>
        </div>
        <div id="cards">
        {cards.map((card, index) => (
          <div className="card" key={index}>
            <Card
                id={index}
                color={card.backgroundColor}
                value={card.value}
                flipped={card.flipped}
                flippedCount={flippedCorrectCount}
                setFlippedCount={setFlippedCount}
                flippedIndexes={flippedIndexes}
                setFlippedIndexes={setFlippedIndexes}
            />
          </div>
        ))}
      </div>
      <div style={{border: 'solid'}} className='alert alert-primary'>
        {feedback}
      </div>
    </>;


}

function Card({
    id,
    color,
    value,
    flipped,
    flippedCount,
    setFlippedCount,
    flippedIndexes,
    setFlippedIndexes,
  }) {

    var [memoryCardRendered, setMemoryCard] = useState([]);
    var [flip, setFlip] = useState(flipped);

    useEffect(() => {
        //console.log('Repaint based on flip change : ' + flip + " of cardId: " + id + " flippedStateObserved: "+flipped);
        const memoryCardRerender = [];
        if (flip && ((!flipped && flippedIndexes.length > 0) || (flipped && flippedIndexes.length === 0))) {
            memoryCardRerender.push(
                <div
                    className="c front"
                    style={{
                        background: color,
                    }}
                    key={id+flip}>
                        <p style={{fontSize: '400%'}}>{value}</p>
            </div>);
            setFlip(true);
        } else {
            memoryCardRerender.push(
                <div
                    className="c back"
                    key={id+flip}
            ></div>);
            setFlip(false);
        }
        setMemoryCard(memoryCardRerender);
    }, [flip, flipped, color, value, id, setMemoryCard, flippedIndexes, setFlip]);


    const onCardClick = () => {
        //console.log(id + " card was clicked. Current State = " + flipped + " : flippedCount = " + flippedCount)

        if (!flip && flippedIndexes.length < 2) {
            setFlip(!flip);
            setFlippedCount(flippedCount + 1);
            flippedIndexes.push(id);
            setFlippedIndexes(flippedIndexes);
        }
    }

    return <>
        <div onClick={onCardClick} key={id}>
          {memoryCardRendered}   
        </div>
    </>
  }