
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

import Memorize from './components/memory-game/memorize';
import Welcome from './components/welcome/welcome';
import Thanks from './components/thanks/thanks';
import Reference from './components/reference/reference';
import Over from './components/itIsOver';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Over />} />
          <Route path="/" element={<Welcome />} />
          <Route path="/memorize" element={<Memorize />} />
          <Route path="/reference" element={<Reference />} />
          <Route path="/thank-you" element={<Thanks />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
