import React from 'react';


import { generateKey } from './gameUtil';

export default function MemoryLearning({ symbolColorMap }) {

    const renderedLearning = [];
    var count = 1;
    symbolColorMap?.forEach((values, colorId) => {
        renderedLearning.push(
            <div className="col" style={{background: colorId, padding: '30px', margin: '20px'}}>
                <div className='container' key={colorId}>
                    <div className="row justify-content-md-center">
                        {values.map((value) => {
                            return <>
                            <div className="col" key={colorId+value}>
                                <p style={{fontSize: '400%'}}>{value}</p>
                            </div>
                            </>;
                        })}
                    </div>
                </div>
            </div>
        );
        if (count % 5 === 0) {
            renderedLearning.push(<div className="w-100"></div>);
        }
        count = count + 1;
    }
    );

    if (renderedLearning.length === 0) return <div>loading ...</div>;

    return <>
        <h1> Remember the symbols and the colors they belong to</h1>
        <div className="container">
            <div className="row justify-content-md-center">{renderedLearning}</div>
        </div>
    </>;
}