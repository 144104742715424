
var possibleSymbols = ['\u25A0','\u2600','\u2601','\u2602','\u2607','\u2609','\u260E','\u260D','\u2612','\u2618','\u261A','\u261C','\u2622','\u262E','\u263A','\u265B','\u267B','\u26A0','\u26F7', '\u26BD'];
var possibleColors = ['#4169e1','#e32636','#efdecd','#ffbf00','#9966cc','#a4c639','#00ffff','#7fffd4','#ff9966','#a52a2a','#9f8170','#66ff00','#5f9ea0','#e7feff','#ff00ff','#ff4f00'];

export const getPossibleColors = () => {
    return possibleColors;
}

export const getPossibleSymbols = () => {
    return possibleSymbols;
}

export const generateKey = (pre) => {
    return `${ pre }_${ new Date().getTime() }`;
}

export const createInitialVariant = (variant, flipped) => {
    var init = null;
    if (variant === 'true') {
        init = true;
    }
    if (variant === 'false') {
        init = false;
    }

    if(init !== null) {
        if (flipped) {
            return !init;
        } else {
            return init;
        }
    }
    
    console.log('create random variant');
    return Math.random() <= 0.5 ? true : false;
}

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export function createGameData(amountEntries) {
    const symMap = new Map();
    var addedColors = [];
    var addedSymbols = [];
    var countEntries = 0;

    while(countEntries < amountEntries) {

        const fetchedColor = getPossibleColors()[getRandomInt(getPossibleColors().length)];
        const fetchedSymbol = getPossibleSymbols()[getRandomInt(getPossibleSymbols().length)];

        if ((addedColors.includes(fetchedColor) || addedSymbols.includes(fetchedSymbol)) && (amountEntries < getPossibleSymbols().length && amountEntries < getPossibleColors().length)) {
            continue;
        }

        addedColors.push(fetchedColor);
        addedSymbols.push(fetchedSymbol);
        symMap.set(fetchedColor, [fetchedSymbol]);

        countEntries = countEntries + 1;
    }

    return symMap;
}

export const createInitialSolutionListWithGameBoard = (symbolColorMap, possibleSymbols) => {
    const solutionList = [];

    possibleSymbols.forEach((symbol, index) => {
        //[...map.values()].find(entry => entry.elements.books.includes(book));
        var correctColor = null;
        symbolColorMap.forEach((values, colorId) => {
            values.forEach((value) => {
                if(value === symbol) {
                    correctColor = colorId;
                    return;
                }
            });
        });
        //console.debug('Searching for ' + symbol + ' and found appropriate color ' + correctColor + '. Placing on index: '+ index);

        solutionList.push({
            symbol: symbol,
            correctColor: correctColor,
            selectedColor: null
        });
    });

    return solutionList;
}

export const createCardsArrayDependingOnVariant = (symbolColorMap, variant) => {
    var cards = [];
    var index = 0;
    symbolColorMap.forEach((values, colorId) => {
        values.forEach((value) => {
            if (variant) {
                //variant 1, match pictures with background color + symbol
                var memoryCard = {
                    id: index,
                    value: value,
                    backgroundColor: colorId,
                    flipped: false
                };
                cards.push(memoryCard);
                cards.push(memoryCard);
            } else {
                //variant 2, one card with symbol, one with background
                cards.push({
                    id: index,
                    value: value,
                    backgroundColor: '#FFFFFF',
                    hiddenColor: colorId,
                    flipped: false
                });
                cards.push({
                    id: index,
                    value: '\u00A0',
                    backgroundColor: colorId,
                    flipped: false
                });
            }
            index ++;
        });

    });
    return shuffle(cards);
}

function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex > 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

export const exchangeId = (setCards, cards, indexInvalidColorCard, indexValidCard) => {
    console.debug('Color matchs, exchange id of cards');
    const matchedCard = cards[indexValidCard];
    const invalidCardToBeSwitched = cards[indexInvalidColorCard];
    
    const oldObject = cards.find(card => (card.id === matchedCard.id && !card.hiddenColor));
    const oldId = oldObject.id;
    let indexInvalidColorCardTwo = cards.indexOf(oldObject);
    cards.fill(oldObject.id = invalidCardToBeSwitched.id, indexInvalidColorCardTwo, indexInvalidColorCardTwo++);
    cards.fill(invalidCardToBeSwitched.id = oldId, indexInvalidColorCard, indexInvalidColorCard++);

    setCards(cards);
}

//https://stackoverflow.com/questions/12462318/find-a-value-in-an-array-of-objects-in-javascript
// let obj = array.find(x => x.name === 'string 1');
// let index = array.indexOf(obj);
// array.fill(obj.name='some new string', index, index++);

// function MemoryGame({ symbolColorMap, variant }){

//     const [cards, setCards] = useState(createCardsFromSymbolMap(symbolColorMap, variant));
//     const [flippedCount, setFlippedCount] = useState(0);
//     const [flippedIndexes, setFlippedIndexes] = useState([]);
    
//     useEffect(() => {
//         // Loads when the game variable changes
//     }, [cards])

//     useEffect(() => {
//         console.log('base flipped change');
//         if (flippedIndexes.length === 2) {
//             const match = cards[flippedIndexes[0]].id === cards[flippedIndexes[1]].id
          
//             console.log('Match' + match + " of " + cards[flippedIndexes[0]].id + " AND " + cards[flippedIndexes[1]].id);
            
//             if (match) {
//                 cards[flippedIndexes[0]].flipped = true;
//                 cards[flippedIndexes[1]].flipped = true;
//             } else {
//                 cards[flippedIndexes[0]].flipped = false;
//                 cards[flippedIndexes[1]].flipped = false;
//             }

//             setFlippedIndexes([]);
//             setCards(cards);
//         }
//     }, [flippedIndexes, cards, setCards])

//     console.log('Orign-FlippedCount: '+flippedCount);
    
    
//     if (cards.length === 0) return <div>loading...</div>;

//     /*const renderedCards = [];
//     cards.forEach((card, i) => {
//         renderedCards.push(
//             <div className="memory-card-item" data-card={card.id} key={card.id} >
//                 <div className="memory-card-item-inner">
//                     <div className="memory-card-item-front"></div>
//                     <div className="memory-card-item-back" style={{background: card.backgroundColor}}>
//                         <p style={{fontSize: '8vw'}}>{card.value}</p>
//                     </div>
//                 </div>
//             </div>
//         );
//     });

//     return <>
//         <div>
//             <h1>{variant ? 'Match identical cards': 'Match the symbols to the appropriate colors'}</h1>
//         </div>
//         <div className="memory-game" id="memoryGame">
//             <div className="memory-board">
//                     {renderedCards}
//             </div>
//             <div className="memory-ui">
//                 <span className="memory-moves">Moves: <span id="memoryMoves">0</span></span>
//                 <span className="memory-matches">Matches: <span id="memoryMatches">0</span>
//                     <span className="memory-matches-cards" id="memoryMatchesCards"></span>
//                 </span>
//             </div>
//         </div>
//     </>;*/

//     return <>
//         <div id="cards">
//         {cards.map((card, index) => (
//           <div className="card" key={index}>
//             <Card
//                 id={index}
//                 color={card.backgroundColor}
//                 value={card.value}
//                 cards={cards}
//                 flippedCount={flippedCount}
//                 setFlippedCount={setFlippedCount}
//                 flippedIndexes={flippedIndexes}
//                 setFlippedIndexes={setFlippedIndexes}
//             />
//           </div>
//         ))}
//       </div>
//     </>;

//     function createCardsFromSymbolMap(symbolColorMap, variant) {
//         var cards = [];
//         var index = 0;
//         symbolColorMap.forEach((values, colorId) => {
//             values.forEach((value) => {
//                 if (variant) {
//                     //variant 1, match pictures with background color + symbol
//                     var memoryCard = {
//                         id: index,
//                         value: value,
//                         backgroundColor: colorId,
//                         flipped: false
//                     };
//                     cards.push(memoryCard);
//                     cards.push(memoryCard);
//                 } else {
//                     //variant 2, one card with symbol, one with background
//                     cards.push({
//                         id: index,
//                         value: value,
//                         backgroundColor: '#FFFFFF',
//                         flipped: false
//                     });
//                     cards.push({
//                         id: index,
//                         value: '\u00A0',
//                         backgroundColor: colorId,
//                         flipped: false
//                     });
//                 }
//                 index ++;
//             });

//         });
//         return cards.sort(() => Math.random() - 0.5);
//     }
// }

// function Card({
//     id,
//     color,
//     value,
//     cards,
//     flippedCount,
//     setFlippedCount,
//     flippedIndexes,
//     setFlippedIndexes,
//   }) {
//     const [flipped, set] = useState(false);
//     var [memoryCardRendered, setMemoryCard] = useState([]);
  
//     useEffect(() => {
//         if (cards[id]?.flipped === flipped) {
//             console.log('state changed for id: ' + id + ' from original here '+ flipped + " to : "+ cards[id]?.flipped);
//             set(cards[id]?.flipped);
//         }
//     }, [cards, flipped, set, id])

//     useEffect(() => {
//         console.log('Repaint based on flip change : ' + flipped + " of cardId: " + id);
//         const memoryCardRerender = [];
//         if (flipped) {
//             memoryCardRerender.push(
//                 <div
//                     className="c front"
//                     style={{
//                         background: color,
//                     }}
//                     key={id+flipped}>
//                         <p style={{fontSize: '6vw'}}>{value}</p>
//             </div>);
//         } else {
//             memoryCardRerender.push(
//                 <div
//                     className="c back"
//                     key={id+flipped}
//             />);
//         }
//         setMemoryCard(memoryCardRerender);
//     }, [flipped, color, value, setMemoryCard, id]);
  
//     const onCardClick = () => {
//         console.log(id, flipped, flippedCount, value);
//         if (!flipped && flippedCount % 2 === 0) {
//           set(state => !state)
//           setFlippedCount(flippedCount + 1)
//           const newIndexes = [...flippedIndexes]
//           newIndexes.push(id)
//           setFlippedIndexes(newIndexes);
//           console.log('flip1' + newIndexes);
//         } else if (
//           flippedCount % 2 === 1 && !flipped &&
//           !flippedIndexes.includes(id)
//         ) {
//           set(state => !state)
//           setFlippedCount(flippedCount + 1)
//           const newIndexes = [...flippedIndexes]
//           newIndexes.push(id)
//           setFlippedIndexes(newIndexes)

//           console.log('flip2' + newIndexes);
//         } else {
//             console.log("NOTHING HAPPENS");
//         }
//       }

    
  
//     return (
//       <div onClick={onCardClick} key={id}>
//         {memoryCardRendered}   
//       </div>
//     )
//   }