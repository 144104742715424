import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import { getDatabase, ref, set } from "firebase/database";
import { initializeApp } from 'firebase/app';
import { v4 as uuidv4 } from 'uuid';

import { getPossibleSymbols, getPossibleColors, generateKey, createInitialSolutionListWithGameBoard } from './gameUtil';

export default function MemoryTestKnowledge({ symbolColorMap, variant, replay, flippedOverallCount = null, setFinishedParent }){

    const [finished, setFinished] = useState(false);
    const [solutionList, setSolution] = useState([])
    const [age, setAge] = useState('');
    const [education, setEducation] = useState('');
    const [message, setMessage] = useState('');
    const firebaseConfig = {
        apiKey: "AIzaSyA1QTzQ6JPrCO6bCUvZ6FBTbBRzhOojXwE",
        authDomain: "bachelorarbeit-91afe.firebaseapp.com",
        databaseURL: "https://bachelorarbeit-91afe-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "bachelorarbeit-91afe",
        storageBucket: "bachelorarbeit-91afe.appspot.com",
        messagingSenderId: "964728478167",
        appId: "1:964728478167:web:09118a7d2a8c9732901392",
        measurementId: "G-C5RXQXD0WH"
      };

    useEffect(() => {
        if (solutionList.length === 0) {
            setSolution(createInitialSolutionListWithGameBoard(symbolColorMap, getPossibleSymbols()));
        }
    }, [solutionList, setSolution, symbolColorMap])
                
    const renderedTest = [];
    getPossibleSymbols().forEach((symbolValue, index) => {
        renderedTest.push(
            <>
                <TestCard 
                    value = {symbolValue}
                    index = {index}
                    possibleColors = {getPossibleColors()}
                    solutionList={solutionList}
                    setSolution={setSolution}
                />
            </>
        );
    });


    const handleChangeAge = (event) => {
        setAge(event.target.value);
    };

    const handleChangeEdu = (event) => {
        setEducation(event.target.value);
    };

    function onClickButton() {

        if (age === '' || education === '') {
            setMessage('Please select age and education state to continue');
            return;
        }

        var uuid = localStorage.getItem('uuid');
        if (!uuid) {
            uuid = uuidv4();
            console.error('initial uuid was not set, set now: '+uuid);
            localStorage.setItem('uuid', uuid);
        }

        const savedObject = {
            timestamp: new Date().getTime(),
            variant: variant,
            replay: replay,
            userId: uuid,
            age: age,
            education: education,
            flippedCardCount: flippedOverallCount,
            solutionList : solutionList
          };

        const app = initializeApp(firebaseConfig);
        const database = getDatabase(app);
        set(ref(database, 'surveys/' + uuidv4()), savedObject);
        setFinished(true);

        if (setFinishedParent) {
            setFinishedParent(true);
        }
    }

    if (!finished) {
        return <div>
        <div>
            <h1>Testing</h1>
            <p>Please fill out for every color which symbols were part of this color. Be aware that some symbols were not part of the game. You can reset your selection by pressing the button labeled 'Reset'.</p>
        </div>
        <div className="test-board justify-content-md-center" key={generateKey(solutionList)} style={{marginBottom: '50px'}}>
            <div>
                {solutionList.length > 0 ? renderedTest : ''}
                <br/>
                <div>
                    <FormControl required sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-required-label">Age</InputLabel>
                        <Select
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        value={age}
                        label="Age *"
                        onChange={handleChangeAge}
                        >
                        <MenuItem value={'10'}>Age &le;10</MenuItem>
                        <MenuItem value={'10-20'}>Age &gt; 10 and &le; 20</MenuItem>
                        <MenuItem value={'20-30'}>Age &gt; 20 and &le; 30</MenuItem>
                        <MenuItem value={'30-40'}>Age &gt; 30 and &le; 40</MenuItem>
                        <MenuItem value={'40'}>Age &gt; 40</MenuItem>
                        </Select>
                        <FormHelperText>Required</FormHelperText>
                    </FormControl>
                    <FormControl required sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-required-label">Highest education state</InputLabel>
                        <Select
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        value={education}
                        label="Age *"
                        onChange={handleChangeEdu}
                        >
                        <MenuItem value={'L1'}>Level 1 (Grundschule)</MenuItem>
                        <MenuItem value={'L2'}>Level 2 (Mittelschule/Hauptschule/Unterstufe)</MenuItem>
                        <MenuItem value={'L3'}>Level 3 (Oberstufe mit Matura)</MenuItem>
                        <MenuItem value={'L4'}>Level 4 (Schulen für Gesundheits- und Krankenpflege)</MenuItem>
                        <MenuItem value={'L5'} >Level 5 oder höher (Meister Ausbildung, Studium an FH oder Universität)</MenuItem>
                        </Select>
                    <FormHelperText>Required</FormHelperText>
                </FormControl>
                </div>
                {message ? <div className='alert alert-danger'>{message}</div>: ''}
                <button onClick={onClickButton}>Save selection</button>
                
            </div>
        </div>
    </div>;
    } else {
        //finished
        return <div>
            <h1>Finished</h1>
            <p>Your entries were saved</p>
            <b>Thank you very much for participating :)</b>

            {!replay ? <>
                <div className='alert alert-info'>
                    <p>There exist two versions of this experiment, if you want to try the other version you can go ahead and press the button below.</p>
                    <p>Replaying the experiment helps me gather more diverse data set, but please consider making a break (at least 5 minutes) before your second attempt</p>
                </div>
            </>: ''}

            <div>
                {!replay ? <Link to={'../?replay=true&variant='+!variant}>
                    <button className='btn btn-danger'>Replay again with different variant</button>
                </Link>:<div></div>}
                <Link to="../thank-you">
                    <button>Finish</button>
                </Link>
            </div>
        </div>
    }
    


}
//<form style={{width: '100%'}} action={search} key={generateKey(uuidv4())}>

function TestCard({value, possibleColors, index, solutionList, setSolution}) {

    const [selectedColor, setColor] = useState('#FFFFFF')
    const [changed, setChanged] = useState(false)
    const [updatedElement, setUpdatedElement] = useState(JSON.parse(JSON.stringify(solutionList))[index]); //JSON.parse(JSON.stringify(solutionList))
    const options = [];
    possibleColors.forEach((colorId, index) => {
        options.push(
            <MenuItem value={colorId} style={{fontSize: '2vw', background: colorId}}>&#160;</MenuItem>
        );
    });

    const handleChange = (event) => {
        setColor(event.target.value);
        setChanged(true);
    };

    const handleClick = () => {
        setColor('#FFFFFD');
        setChanged(true);
      };

    useEffect(() => {
        if (selectedColor !== '#FFFFFF') {
            updatedElement.selectedColor = selectedColor;
            setUpdatedElement(updatedElement);
            if (changed) {
                const updatedSolutionList = solutionList.map((solution, i) => {
                    if (i === index) {
                        solution.selectedColor = updatedElement.selectedColor;
                        return solution;
                    } else {
                        return solution;
                    }
                });
                setSolution(updatedSolutionList);
                setChanged(false);
            }
        }
        if (updatedElement.selectedColor) {
            setColor(updatedElement.selectedColor);
        }
    }, [selectedColor, updatedElement, setUpdatedElement, setSolution, solutionList, index, changed]);

    // useEffect(() => {
    //     setSolution(solutionList);
    //     console.log('SOLUTION LIST HAS CHANGED');
    //     console.log(solutionList);
    // }, [setSolution, solutionList]);
//<option value="someOption">Some option</option>
//<p style={{fontSize: '3vw'}} key={generateKey(uuidv4()+value)}>{value}</p>

    return <div style={{display: 'inline-grid', border: 'solid', margin: '1%', padding: '1%', background: selectedColor}}>
        <div key={value}>
            <div>
                <FormControl fullWidth size='medium' style={{marginBottom: '20px'}}>
                    <p style={{fontSize: '200%'}} id={value}>{value}</p>
                    <Select
                        labelId='select-color-for-{value}'
                        id="demo-simple-select-{value}"
                        value={selectedColor}
                        label="Color"
                        onChange={handleChange}
                        style={{ width: '200px'}}
                    >
                        {options}
                    </Select>
                </FormControl>
            </div>
            <div>
                <button className='btn btn-danger' onClick={handleClick}>Reset</button>
            </div>
        </div>
    </div>
}