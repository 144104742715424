import { Box, Divider } from '@mui/material';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom'
import HowToModal from './howToModal';
import { v4 as uuidv4 } from 'uuid';
import { createInitialVariant } from '../memory-game/gameUtil';

export default function Welcome() {

    const [searchParams, setSearchParams] = useSearchParams();
    const [variant, setVariant] = useState(createInitialVariant(searchParams.get('variant')), true);

    var uuid = localStorage.getItem('uuid');
    if (!uuid) {
        uuid = uuidv4();
        console.log('set initial session id: '+uuid);
        localStorage.setItem('uuid', uuid);
    }

    return (
        <div>
            <h2>Welcome to my survey</h2>
            <div>
                {
                    searchParams.get('replay') === 'true' ?
                    <div className='alert alert-info'>
                        <p>Thank you very much for finishing the experiment once, have fun on the second try. Please take a short break before starting again</p>
                    </div>
                    :
                    <p>This experiment is part of my bachelor thesis (if you have further questions you can contact me privately)</p>
                }
                

                <Box style={{margin: '20px'}}>
                    <div style={{display: 'inline-block'}}>
                        <p>The experiment works as follows:</p>
                        <ul style={{textAlign: 'left'}}>
                            <li>You can start by pressing the button below</li>
                            <li>The experiment takes around 15 (+/- 7) minutes to complete</li>
                            <li>The experiment requires your full attention</li>
                            <li>If something did not work, please contact me :)</li>
                            <li>The procedure will be explained shortly</li>
                        </ul> 
                    </div>
                    <br/>
                    <HowToModal variant={variant} replay={searchParams.get('replay')}></HowToModal>
                </Box>

                <Divider/>

                <div className='d-md-none alert alert-warning'>
                    <p style={{color: 'orange'}}>Attention:</p>
                    The experience is optimized for use on devices with a horizontal resolution higher than 800 pixel (desktop devices). 
                    For an optimal experience I recommend using these, but smaller devices should also work.
                </div>

                <h2>Thank you for helping me</h2>

                <div style={{display: 'inline-block'}}>
                    <b>Data collection disclaimer</b>
                    <ul style={{textAlign: 'left'}}>
                        <li>collected data is anonymous and cannot be used to identify individuals</li>
                        <li>collected data will only be used for the context of this bachelor thesis</li>
                        <li>collected data is deleted after it is not needed anymore</li>
                    </ul>
                </div>
            </div>
        </div>);
}

{/* <li>The experiment runs in three distinct phases</li>
                        <ul>
                            <li><b>Learning Phase:</b> You get symbols assigned to specific colors</li>
                            <li><b>Training Phase:</b> You get to play a memory game to train what you have learned (two variations exist)</li>
                            <li><b>Test Phase:</b> Small anonymous test will be done how good knowledge rentition</li>
                        </ul> */}