import React from 'react';

export default function Over() {
  return <>
    <br/>
    <h2>Thank you for your intrest</h2>
    <br/>
    <h2>Experiment-Phase is over</h2>
    <h3>Have a good day :)</h3>
  </>
  ;
}
